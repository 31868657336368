import React from 'react';
import { graphql } from 'gatsby';
import { DetailPageContainerLook } from '../components/detailPage';
import { MarkdownText } from '../components/markdown';
import * as Content from '../components/content';
import { VideoPlayer } from '../components/videoPlayer';

const Page = ({ data }) => {
    return (
        <DetailPageContainerLook>
            <Content.Title title={data.craftJson.title} />
            <VideoPlayer
                media={data.craftJson.videoMedia}
                posterImageSrc={
                    data.craftJson.videoStill.landscape.childImageSharp.resize
                        .src
                }
            />
            <MarkdownText text={data.craftJson.look.text} />
        </DetailPageContainerLook>
    );
};

export const query = graphql`
    query($slug: String!) {
        craftJson(fields: { slug: { eq: $slug } }) {
            fields {
                slug
            }
            title
            look {
                text
            }
            videoStill {
                landscape {
                    childImageSharp {
                        resize(width: 1024) {
                            src
                            width
                            height
                            aspectRatio
                        }
                    }
                }
            }
            videoMedia {
                src
                type
            }
        }
    }
`;

export default Page;
